import React from 'react';
import Link from 'next/link';
import { NavLinks } from './Header.styles';

interface Props {
  onClick?: () => void;
}

const HeaderNavLinks = ({ onClick }: Props) => {
  return (
    <>
      <NavLinks>
        <span onClick={onClick}>
          <Link href="/" passHref>
            Jobs
          </Link>
        </span>
      </NavLinks>
      <NavLinks>
        <span onClick={onClick}>
          <Link href="/blog" passHref>
            Blog
          </Link>
        </span>
      </NavLinks>
    </>
  );
};
export default React.memo(HeaderNavLinks);
